import { render, staticRenderFns } from "./totalCmpt.vue?vue&type=template&id=6d857b9e&scoped=true"
import script from "./totalCmpt.vue?vue&type=script&lang=js"
export * from "./totalCmpt.vue?vue&type=script&lang=js"
import style0 from "./totalCmpt.vue?vue&type=style&index=0&id=6d857b9e&prod&lang=scss"
import style1 from "./totalCmpt.vue?vue&type=style&index=1&id=6d857b9e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d857b9e",
  null
  
)

export default component.exports